a {
    text-decoration: none;
    color: inherit;
}

.thin-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 6px;
    border: 1px solid transparent;
    background-color: rgba(127, 127, 127, 0.5);
    background-clip: padding-box;
}

.thin-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(127, 127, 127, 0.7);
}